var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingLogbook)?_c('FormLogbookSkeleton'):_vm._e(),(!_vm.isLoadingLogbook)?_c('ValidationObserver',{ref:"validationObserver"},[_c('FormInputProject',{attrs:{"name":"projectId","title":"Nama Project","type":"text","disabled":!_vm.isEditable,"rules":"required","custom-messages":{
        required: 'Nama project harus diisi'
      },"value":_vm.payload.projectName},on:{"change":_vm.onSelectedProjectChanged},scopedSlots:_vm._u([(!_vm.isViewingOnly)?{key:"subtitle",fn:function(){return [_c('p',[_c('i',{staticClass:"text-gray-600 dark:text-gray-300",attrs:{"aria-hidden":"true"}},[_vm._v(" Nama project Anda tidak ada? silahkan hubungi admin ")])])]},proxy:true}:null],null,true)}),_c('br'),_c('FormInput',{attrs:{"name":"nameTask","title":"Nama Pekerjaan / Task","type":"text","disabled":!_vm.isEditable,"rules":"required","custom-messages":{
        required: 'Nama pekerjaan / task harus diisi'
      }},model:{value:(_vm.payload.nameTask),callback:function ($$v) {_vm.$set(_vm.payload, "nameTask", $$v)},expression:"payload.nameTask"}}),_c('br'),_c('FormInputTupoksi',{attrs:{"name":"tupoksiJabatanId","show-as-readonly-input":_vm.isViewingOnly},model:{value:(_vm.payload.tupoksiJabatanId),callback:function ($$v) {_vm.$set(_vm.payload, "tupoksiJabatanId", $$v)},expression:"payload.tupoksiJabatanId"}}),_c('br'),_c('FormInputDateTime',{attrs:{"name":"dateTask","title":"Tanggal Pengerjaan","type":"date","disabled":!_vm.isEditable,"max-datetime":_vm.maxDateTime,"rules":"required","custom-messages":{
        required: 'Tanggal pengerjaan harus diisi'
      }},model:{value:(_vm.payload.dateTask),callback:function ($$v) {_vm.$set(_vm.payload, "dateTask", $$v)},expression:"payload.dateTask"}}),_c('br'),_c('FormInputDifficulty',{attrs:{"name":"difficultyTask"},model:{value:(_vm.payload.difficultyTask),callback:function ($$v) {_vm.$set(_vm.payload, "difficultyTask", $$v)},expression:"payload.difficultyTask"}}),_c('br'),_c('FormInputEvidence',{ref:"formInputEvidence",attrs:{"name":"evidenceTask","title":"Foto Hasil Kerja/Foto Kegiatan*","url":_vm.payload.evidenceTaskURL,"path":_vm.payload.evidenceTaskPath,"disabled":!_vm.isEditable,"rules":"required|mimes:image/*|size:5120","accept":"image/*","custom-messages":{
        required: 'Foto harus diisi',
        mimes: 'File harus berupa gambar',
        size: 'Gambar tidak boleh lebih dari 5MB'
      }},on:{"update:url":function($event){return _vm.$set(_vm.payload, "evidenceTaskURL", $event)}},scopedSlots:_vm._u([(!_vm.isViewingOnly)?{key:"subtitle",fn:function(){return [_c('span',{staticClass:"text-gray-700 dark:text-gray-400"},[_vm._v(" File tidak boleh lebih dari 5MB ")])]},proxy:true}:null],null,true)}),_c('br'),_c('FormInputLink',{ref:"formInputDocumentLink",attrs:{"show-as-readonly-link":!_vm.isEditable,"tupoksi-id":_vm.payload.tupoksiJabatanId,"value":_vm.documentTaskLink},on:{"change":_vm.onDocumentTaskLinkChanged}}),_c('br'),_c('FormInput',{attrs:{"name":"workPlace","title":"Tempat Pengerjaan","type":"text","placeholder":"Tempat pengerjaan","disabled":!_vm.isEditable,"rules":"required","custom-messages":{
        required: 'Tempat pengerjaan harus diisi'
      }},model:{value:(_vm.payload.workPlace),callback:function ($$v) {_vm.$set(_vm.payload, "workPlace", $$v)},expression:"payload.workPlace"}}),_c('br'),_c('FormInput',{attrs:{"type":"text","name":"organizerTask","title":"Penyelenggara","disabled":!_vm.isEditable,"rules":"required","custom-messages":{
        required: 'Penyelenggara harus diisi'
      }},model:{value:(_vm.payload.organizerTask),callback:function ($$v) {_vm.$set(_vm.payload, "organizerTask", $$v)},expression:"payload.organizerTask"}}),_c('br'),(_vm.isEditable)?_c('div',{staticClass:"flex justify-end"},[_c('button',{staticClass:"mr-4 button focus:outline-none\n               bg-gray-200 hover:bg-gray-300 text-gray-500",on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button focus:outline-none\n               bg-brand-blue hover:bg-brand-blue-lighter text-white",on:{"click":_vm.beforeSave}},[_vm._v(" Save ")])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }