var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormInputHeader',{attrs:{"label-for":_vm.name,"title":_vm.title,"subtitle":_vm.subtitle,"required":_vm.required},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("title")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._t("subtitle")]},proxy:true}],null,true)}),_c('ValidationProvider',{ref:"validator",staticClass:"hidden",attrs:{"rules":_vm.rules,"custom-messages":_vm.customMessages,"tag":"div"},on:{"hook:mounted":_vm.onValidatorMounted}},[_c('input',{attrs:{"type":"radio","disabled":_vm.disabled,"name":_vm.name},domProps:{"value":_vm.value,"checked":_vm.value !== null && typeof _vm.value !== 'undefined' ? true : false}})]),_c('div',{class:{
      'mb-2 rounded overflow-hidden border border-solid border-brand-blue': true,
      'flex-row flex-no-wrap justify-center items-stretch': true,
      'flex': _vm.block,
      'inline-flex': !_vm.block
    }},_vm._l((_vm.options),function(opt,index){return _c('button',{key:index,class:{
          'flex-1 px-6 py-2 border-solid outline-none focus:outline-none': true,
          'border-l': index !== 0,
          'text-white border-brand-blue bg-brand-blue hover:bg-brand-blue-light': _vm.getOptionValue(opt) === _vm.value,
          'text-gray-500 dark:text-gray-300 border-gray-300': _vm.getOptionValue(opt) !== _vm.value,
          'pointer-events-none': _vm.disabled
        },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.onOptionSelected(opt)}}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(opt))+" ")])}),0),(_vm.errors.length)?_c('p',{staticClass:"form-input__error-hint"},[_vm._t("error",[_vm._v(" "+_vm._s(_vm.errors[0])+" ")])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }